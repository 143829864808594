<template>
  <!-- TODO -->
  <SceneBase>
    <v-container class="pa-0" fluid fill-height>
      <transition :name="transition_name" mode="out-in">
        <v-row fill-height align-content="center" justify="center">
          <v-img
            :src="url_image"
            max-height="100vh"
            contain
            @load="onImageLoad"
            @error="
              () => {
                $emit('on-image-error', { url: 'coming-soon.jpg' });
              }
            "
          ></v-img>
        </v-row>
      </transition>
    </v-container>
    <!-- REPETITIVE SIGNAL -->
    <repetitive-signal
      key="duration"
      :ready="start_repetitive"
      :milliseconds="duration"
      v-on:repetitive-signal-over="onRepetitiveEnd"
    ></repetitive-signal>
    <!-- END OF REPETITIVE SIGNAL -->
  </SceneBase>
</template>

<script>
import SceneBase from "@/components/Scenes/Base";

import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";

export default {
  name: "SceneError",

  components: { SceneBase, RepetitiveSignal },

  props: {
    start_duration: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: null,
    },
    transition_name: {
      type: String,
      default: "fade",
    },
    url_image: {
      type: String,
      default: require("@/assets/coming-soon.jpg"),
    },
  },
  data() {
    return {
      start_repetitive: false
    };
  },

  methods: {
    onImageLoad() {
      if (this.start_duration) this.start_repetitive = true;
    },
    onRepetitiveEnd() {
      this.$emit("scene-error-end", { ms: this.duration });
    },
  },
};
</script>

<style lang="scss" scoped></style>
