import screenfull from "screenfull";

const fullscreen = {
    methods: {
        launchFullscreen() {
            let element = document.documentElement;

            if (!document.fullscreenElement) {
                screenfull.request(element);
            }
        },

        exitFullscreen() {
            let element = document.documentElement;

            if (document.fullscreenElement) {
                screenfull.exit(element);
            }
        },

    },

}

export default fullscreen;