<template>
  <SceneBase style="max-height: 100vh; max-width: 100vw">
    <!-- <span v-on:dblclick="testtt" v-on:click="testtt">sddsdsdsdsdsddsds</span> -->
    <!-- https://www.npmjs.com/package/use-double-click -->

    <Logo key="company" :logo="logo_url" :class="{ 'pt-10': smallDetailsCar }" topLeft />
    <Logo
      key="mb"
      :logo="require('@/assets/logo/stamp white.svg')"
      :bottomRight="!detailsCar_right"
      :bottomLeft="detailsCar_right"
      opacity
    />

    <!-- Traffic -->
    <transition name="fade" mode="out-in">
      <SceneTraffic
        :show="diapo_type == scene_enum.traffic"
        v-show="diapo_type == scene_enum.traffic"
        :duration="nbr_millisecond_duration_trafic"
        :interval="nbr_millisecond_interval_trafic"
        v-on:traffic-ready="onTrafficReady"
        v-on:traffic-end="onTrafficEnd"
      ></SceneTraffic>
    </transition>

    <transition name="fade" mode="out-in">
      <SceneWeather
        :show="diapo_type == scene_enum.weather"
        v-show="diapo_type == scene_enum.weather"
        :interval="nbr_millisecond_interval_weather"
        :duration="nbr_millisecond_duration_weather"
        v-on:weather-ready="onWeatherReady"
        v-on:weather-end="onWeatherEnd"
        :ref_weather="ref_trace_weather"
      />
    </transition>
    <!-- Traffic -->

    <!-- Vehicule -->
    <transition name="fade" mode="out-in">
      <SceneVehicule
        :key="vehicule_refresh_key"
        v-if="diapo_type == scene_enum.vehicule"
        :url_image="url_vehicule"
        :display_top_title="$vuetify.breakpoint.mdAndUp"
        :car_data="car_data"
        :loyer="loyer && display_details_car"
        :loyer_duration="loyer_duration"
        :loyer_taeg="loyer_taeg"
        :display_details_car="display_details_car"
        :detailsCar_overImage="detailsCar_overImage"
        :detailsCar_right="detailsCar_right"
        :smallDetailsCar="smallDetailsCar"
        :duration="nbr_millisecond_per_photo"
        :display_condition_loyer="display_condition_loyer"
        :transition_duration="transition_duration"
        transition_name="fade"
        v-on:vehicule-end="onVehiculeEnd"
        v-on:on-image-error="onImageError"
      />
    </transition>
    <!-- Vehicule -->

    <!-- https://vimeo.com/467739681 -->
    <!-- Publicite -->
    <transition name="fade" mode="out-in">
      <ScenePublicite
        v-if="diapo_type == scene_enum.pub"
        :key="publicite_refresh_key"
        :type="publicite_type"
        :url="publicite_url"
        :duration_image_publicite="nbr_millisecond_per_photo"
        v-on:on-publicite-end="onPubliciteEnd"
        v-on:on-image-error="onImageError"
      />
    </transition>
    <!-- Publicite -->
    <!-- Error -->
    <transition name="fade" mode="out-in">
      <SceneError
        v-if="diapo_type == scene_enum.err"
        :duration="nbr_millisecond_per_photo"
        v-on:scene-error-end="onSceneErrorEnd"
        v-on:on-image-error="onImageError"
      />
    </transition>
    <!-- Error -->
  </SceneBase>
</template>

<script>
import { mapGetters } from "vuex";
import default_options_values from "@/utils/defaultOptionsValues";

import screenfull from "screenfull";

import SceneBase from "@/components/Scenes/Base";
import SceneVehicule from "@/components/Scenes/SceneVehicule";
import SceneTraffic from "@/components/Scenes/SceneTraffic";
import ScenePublicite from "@/components/Scenes/ScenePublicite";
import SceneWeather from "@/components/Scenes/SceneWeather";
import SceneError from "@/components/Scenes/SceneError";

import Logo from "@/components/Diapo/Logo/Logo.vue";

import fullscreen from "@/utils/mixins/fullscreen";

import mixins_user from "@/utils/mixins/user";
import mixins_logo from "@/utils/mixins/logo";

export default {
  name: "Diapo",

  components: { Logo, SceneBase, SceneVehicule, SceneTraffic, ScenePublicite, SceneWeather, SceneError },

  mixins: [fullscreen, mixins_user, mixins_logo],

  data() {
    return {
      /**
       * General
       */
      diapo_type: "",
      params: {},
      transition_duration: 1000,

      /** Loyer */
      loyer: false,
      loyer_duration: null,
      loyer_taeg: null,

      /**
       * Logo
       */
      logo_url: null,
      logo_type: default_options_values.logo_type,
      logo_company_name: null,

      /**
       * Weather
       */
      nbr_millisecond_interval_weather: null, //1 * 1000 * 60 * default_options_values.weather_interval, //  interval of weather : 20 minutes by default
      nbr_millisecond_duration_weather: null, //default_options_values.weather_duration * 1000, // default weather duration : 15 secondes
      ref_trace_weather: [true, true, true], // immutable array, uses as a reference only

      /**
       * Trafic
       */
      nbr_millisecond_interval_trafic: null, //1000 * 60 * 15, //  interval of traffic : 15 minutes by default
      nbr_millisecond_duration_trafic: null, //1000 * 15 // default weather duration : 15 secondes

      /**
       * Scenes
       */
      map_fn: {},
      scene_enum: {},

      /**
       * Vehicule
       */
      car_data: {},
      old_car_data: {},
      url_vehicule: null,
      display_condition_loyer: true,
      // display_top_title: true,
      ref_display_details_car: false,
      display_details_car: false,
      vehicule_refresh_key: new Date().toString(),
      nbr_millisecond_per_photo: default_options_values.nbr_second_per_photo * 1000,

      detailsCar_overImage: true,
      detailsCar_right: false,
      smallDetailsCar: default_options_values.smallDetailsCar,
      /**
       * Publicite
       */
      publicite_type: null, // EITHER IMAGE OR VIDEO
      publicite_url: "", // vimeo - video url
      publicite_refresh_key: new Date().toString(),

      /**
       * Traffic
       */
      time_for_trafic: false,

      /**
       * Weather
       */
      time_for_weather: false,
    };
  },

  async mounted() {
    await this.setup();
    await this.startIteration();
  },

  beforeDestroy() {
    this.exitFullscreen();
  },

  beforeRouteLeave(to, from, next) {
    this.exitFullscreen();
    next();
  },

  methods: {
    async setup() {
      let query = this.getQueryDiapo();

      this.params = { ...query };
      this.nbr_millisecond_per_photo = query?.nbr_second_per_photo * 1000 || default_options_values.nbr_second_per_photo * 1000;

      /**
       * General
       */

      if (!query.detailsCar_overImage) this.detailsCar_overImage = false;
      if (query.display_details_car) {
        this.ref_display_details_car = true;
        this.display_details_car = true;
      }
      if (query.detailsCar_right) this.detailsCar_right = true;

      if (query.smallDetailsCar) this.smallDetailsCar = true;

      /**
       * Logo
       */

      if (query.logo_type) this.logo_type = query.logo_type;
      if (query.logo_company_name) this.logo_company_name = query.logo_company_name;

      /**
       * weather
       */

      if (query.weather_local) {
        // this.params.weather_local = true;
        this.ref_trace_weather.splice(2, 1, false);
      }
      if (query.weather_regional) {
        // this.params.weather_regional = true;
        this.ref_trace_weather.splice(1, 1, false);
      }
      if (query.weather_national) {
        // this.params.weather_national = true;
        this.ref_trace_weather.splice(0, 1, false);
      }

      if (this.ref_trace_weather.includes(false)) {
        this.nbr_millisecond_interval_weather = 1000 * 60 * default_options_values.weather_interval; //  interval of weather : 20 minutes by default
        this.nbr_millisecond_duration_weather = default_options_values.weather_duration * 1000; // default weather duration : 15 secondes
      }

      if (query.weather_interval) this.nbr_millisecond_interval_weather = 1000 * 60 * query.weather_interval;
      if (query.weather_duration) this.nbr_millisecond_duration_weather = query.weather_duration * 1000;

      /**
       * trafic
       */

      if (query.trafic_trafic) {
        this.nbr_millisecond_interval_trafic = 1000 * 60 * default_options_values.trafic_interval;
        this.nbr_millisecond_duration_trafic = 1000 * default_options_values.trafic_duration;
      }
      if (query.trafic_interval) {
        this.nbr_millisecond_interval_trafic = 1000 * 60 * query.trafic_interval;
      }
      if (query.trafic_duration) {
        this.nbr_millisecond_duration_trafic = 1000 * query.trafic_duration;
      }

      /**
       * loyer
       */

      if (query.loyer_loyer) {
        this.loyer = true;
        this.loyer_duration = Number(default_options_values.loyer_duration);
        this.loyer_taeg = Number(default_options_values.loyer_taeg) / 100;
      }
      if (query.loyer_duration) {
        this.loyer_duration = Number(query.loyer_duration);
      }
      if (query.loyer_taeg) {
        this.loyer_taeg = Number(query.loyer_taeg) / 100;
      }

      /**
       * create a pointer to fn
       */
      this.scene_enum = Object.freeze({
        vehicule: "vehicule",
        pub: "publicite",
        traffic: "traffic",
        weather: "weather",
        err: "error",
        wait: "waiting",
      });
      this.map_fn = Object.freeze({ vehicule: this.handleVehicule, pub: this.handlePublicite });

      /**
       * logo request
       */
      try {
        this.launchFullscreen();
        this.findLogoFromType();
      } catch (err) {
        console.error(err);
      }
    },

    async findLogoFromType() {
      let res = { url: null };
      try {
        if (this.logo_type == "concession") {
          res = await this.getSpecificConcessionLogo();
        } else if (this.logo_type == "groupe" && this.logo_company_name) {
          res = await this.getSpecificGroupeLogo(this.logo_company_name);
        } else if (this.logo_type == "franchise" && this.logo_company_name) {
          res = await this.getSpecificFranchiseLogo(this.logo_company_name);
        }
      } catch (error) {
        console.error(error);
        this.logo_url = null;
      }
      this.logo_url = res?.url ?? null;
    },

    async startIteration() {
      let data = null;

      try {
        this.launchFullscreen();

        if (this.time_for_trafic) {
          await this.handleTraffic();
        } else if (this.time_for_weather) {
          await this.handleWeather();
        } else {
          data = (
            await this.$axios.post("/diapo", this.params, {
              timeout: 30000, // 30 s of timeout
            })
          ).data;

          /**
           * data.type == ["vehicule", "pub"]
           */

          await this.extractData(data);
        }
      } catch (error) {
        console.error(error);

        await this.onCatchingError();
      }
    },

    async extractData(data) {
      if (!this.map_fn.hasOwnProperty(data.type)) {
        console.error(`Couldn't map type ${data.type}`);
        throw new Error(`Couldn't map type ${data.type}`);
      }
      await this.map_fn[data.type](data);
    },

    async handleVehicule(data) {
      this.displayAllVehiculeDetails();

      if (this.diapo_type != "vehicule" || data.details._id != this.old_car_data._id) {
        // change the vehicule picture and its details
        await this.triggerAnimationNewScene("vehicule");
        this.car_data = data.details;
      }
      this.old_car_data = Object.assign({}, this.car_data);
      this.url_vehicule = data.url;
    },

    async handlePublicite(data) {
      if (data.format == "video" || data.format == "img") {
        this.publicite_type = data.format == "video" ? "video" : "image";
        this.publicite_url = data.url;
        this.publicite_refresh_key = new Date().toString();
        await this.triggerAnimationNewScene("publicite");
      } else await this.onCatchingError();
    },

    /**
     * Indicate that traffic is ready to be displayed
     */
    async handleTraffic() {
      await this.triggerAnimationNewScene("traffic");
    },

    /**
     *
     */
    async handleWeather() {
      await this.triggerAnimationNewScene("weather");
    },
    /**
     * @scene string
     *
     * Switch Scene
     * */
    async setNewScene(scene) {
      if (this.diapo_type != scene) {
        this.diapo_type = scene;
      }
    },

    /**
     * @scene string
     *
     * Switch Scene but in order to trigger vuejs transition, we have to force an update of diapo_type
     * */
    async triggerAnimationNewScene(scene) {
      if (this.diapo_type != scene) {
        this.diapo_type = scene;
      } else {
        this.diapo_type = null;
        await this.$nextTick();
        await new Promise((resolved) => setTimeout(resolved, this.transition_duration));
        this.diapo_type = scene;
      }
    },

    onTrafficReady() {
      this.time_for_trafic = true;
    },

    async onTrafficEnd() {
      this.time_for_trafic = false;
      await this.triggerAnimationNewScene("waiting");
      this.startIteration();
    },

    onWeatherReady() {
      this.time_for_weather = true;
    },

    async onWeatherEnd() {
      this.time_for_weather = false;
      await this.triggerAnimationNewScene("waiting");
      this.startIteration();
    },

    onVehiculeEnd() {
      this.startIteration();
    },

    async onPubliciteEnd() {
      await this.triggerAnimationNewScene("waiting");
      this.startIteration();
    },

    async onSceneErrorEnd() {
      await this.triggerAnimationNewScene("waiting");
      this.startIteration();
    },

    onImageError() {
      this.startIteration();
    },

    /**
     * Should refactor it for an "SceneError"
     */
    async onCatchingError() {
      // on a trycatch error
      console.error("onCatchingError");
      // this.hideAllVehiculeDetails();
      // this.url_vehicule = require("@/assets/coming-soon.jpg");
      await this.triggerAnimationNewScene("error");
      // this.diapo_type = "vehicule";
      // // this.vehicule_refresh_key = new Date().toString();

      // await this.setStartRepetitiveSignal(true);
    },

    hideAllVehiculeDetails() {
      this.display_condition_loyer = false;
      this.display_details_car = false;
    },

    displayAllVehiculeDetails() {
      this.display_condition_loyer = true;
      if (this.ref_display_details_car == true) this.display_details_car = true;
    },

    /** Parameter handler */
    transformGroupeIntoQuery(groupe) {
      let res = [];
      if (Array.isArray(groupe)) {
        groupe?.map((el) => {
          let slides = el?.slides?.join(",");
          res.push(`${el.name}:${slides.length > 0 ? slides : "all"}`);
        });
        return !res.length ? "none" : res;
      }
      return groupe;
    },

    getDefaultOptions() {
      return {
        // publicite
        concession_cSlides_list: default_options_values.concession_cSlides_list,
        groupe_cSlides_list: default_options_values.groupe_cSlides_list,
        franchise_cSlides_list: default_options_values.franchise_cSlides_list,

        concession_frequency_cSlides: default_options_values.concession_frequency_cSlides,
        groupe_frequency_cSlides: default_options_values.groupe_frequency_cSlides,
        franchise_frequency_cSlides: default_options_values.franchise_frequency_cSlides,

        // photo
        max_photo_per_vehicule: default_options_values.max_photo_per_vehicule,
        nbr_second_per_photo: default_options_values.nbr_second_per_photo,

        //logo
        logo_type: default_options_values.logo_type, // concession // groupe // franchise
        logo_company_name: null, // only for type == groupe || type == franchise

        //detailsCar
        detailsCar_overImage: default_options_values.detailsCar_overImage,
        // detailsCar_right: default_options_values.detailsCar_right,
      };
    },

    getWeatherDefaultOptions(weather) {
      let res = {};
      let weatherDefault = {
        weather_duration: default_options_values.weather_duration,
        weather_interval: default_options_values.weather_interval,
      };

      if (this.isRole("weather") && (weather.local || weather.regional || weather.national)) {
        res = { ...weatherDefault };
        for (const el in weather) {
          if (typeof weather[el] == "boolean" && weather[el] != false) res[`weather_${el}`] = weather[el];
          else if (typeof weather[el] != "boolean" && weather[el] != null && weather[el] != undefined)
            res[`weather_${el}`] = weather[el];
        }
      }

      return res;
    },

    getTraficDefaultOptions(trafic) {
      let res = {};
      let traficDefault = {
        trafic_duration: default_options_values.trafic_duration,
        trafic_interval: default_options_values.trafic_interval,
      };

      if (this.isRole("traffic") && trafic.trafic) {
        res = { ...traficDefault };
        for (const el in trafic) {
          if (typeof trafic[el] == "boolean" && trafic[el] != false) res[`trafic_${el}`] = trafic[el];
          else if (typeof trafic[el] != "boolean" && trafic[el] != null && trafic[el] != undefined)
            res[`trafic_${el}`] = trafic[el];
        }
      }

      return res;
    },

    getLoyerDefaultOptions(loyer) {
      let res = {};
      let loyerDefault = {
        loyer_duration: default_options_values.loyer_duration,
        loyer_taeg: default_options_values.loyer_taeg,
      };

      if (loyer.loyer) {
        res = { ...loyerDefault };
        for (const el in loyer) {
          if (typeof loyer[el] == "boolean" && loyer[el] != false) res[`loyer_${el}`] = loyer[el];
          else if (typeof loyer[el] != "boolean" && loyer[el] != null && loyer[el] != undefined) res[`loyer_${el}`] = loyer[el];
        }
      }

      return res;
    },

    getLogoDefaultOptions(logo) {
      let res = {};
      let logoDefault = {
        logo_type: default_options_values.logo_type,
        logo_company_name: null,
      };

      res = { ...logoDefault };
      for (const el in logo) {
        if (logo[el] != null && logo[el] != undefined) res[`logo_${el}`] = logo[el];
      }

      return res;
    },

    getQueryDiapo() {
      const saved_options = this.get_user?.options_diapo;
      var query = {};

      query = {
        ...this.getDefaultOptions(),
        ...this.getWeatherDefaultOptions(saved_options?.weather ?? {}),
        ...this.getTraficDefaultOptions(saved_options?.trafic ?? {}),
        ...this.getLoyerDefaultOptions(saved_options?.loyer ?? {}),
        ...this.getLogoDefaultOptions(saved_options?.logo ?? {}),
        ...(saved_options?.photo ?? {}),

        ...(saved_options?.publicite ?? {}),
        display_details_car: saved_options?.display_details_car ?? default_options_values.display_details_car,
        smallDetailsCar: saved_options?.smallDetailsCar ?? default_options_values.smallDetailsCar,
        concession_cSlides_list: !saved_options?.publicite?.concession_cSlides_list?.length
          ? "none"
          : saved_options?.publicite?.concession_cSlides_list,
        groupe_cSlides_list: this.transformGroupeIntoQuery(saved_options?.publicite?.groupe_cSlides_list ?? []),
        franchise_cSlides_list: this.transformGroupeIntoQuery(saved_options?.publicite?.franchise_cSlides_list ?? []),
        /**
         * We have to change filters keys so that it matches what the backend expects
         */
        vehicule_constructor_list:
          saved_options?.filters?.selected_constructors?.map((el) => el?.id ?? null)?.filter((el) => el != null) ?? [],
        cardiff_codes_list: saved_options?.filters?.selected_cardiff_codes ?? [],
        qualification_list:
          saved_options?.filters?.selected_qualifications?.map((el) => el?._id ?? null)?.filter((el) => el != null) ?? [],
        genre_list: saved_options?.filters?.selected_genres?.map((el) => el?._id ?? null)?.filter((el) => el != null) ?? [],
      };

      var to_del = [];

      for (const el in query) {
        if (
          query[el] == null ||
          query[el] == undefined ||
          query[el] == false ||
          (Array.isArray(query[el]) && !query[el].length && !el.includes("cSlides_list")) ||
          (el.includes("frequency_cSlides") && query[`${el.split("_")[0]}_cSlides_list`] == "none") // delete every empty array except *_cSlides_list keys
        )
          to_del.push(el);
      }

      to_del.map((el) => delete query[el]);

      return query;
    },

    /** */
    // Find the right method, call on correct element
    launchFullscreen() {
      let element = document.documentElement;

      if (!screenfull.isFullscreen && screenfull.request) {
        screenfull.request(element);
      }
    },

    exitFullscreen() {
      let element = document.documentElement;

      if (screenfull.isFullscreen) {
        screenfull.exit(element);
      }
    },
  },

  computed: {
    ...mapGetters(["get_user"]),
  },
};
</script>

<style scoped>
/* @import "~@/styles/variables.scss"; */

.no-cursor:hover {
  cursor: none;
}

.hide-cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: none;
  z-index: 100;
}
</style>
